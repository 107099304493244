import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { WidgetService } from '../../../../../@core/services/automations/widget.service';
import { WidgetCommon } from '../../../../../@core/types/Automations/Widgets/Widget';
import { validateLinkUrl } from '../../../../../@core/validators/linkValidator';
import { Subscription } from 'rxjs';
import { ProductDetails, ProductListDetails } from './types';

export type ProductListWidget = WidgetCommon & {
  widget: ProductDetails[];
};

@Component({
  selector: 'norby-product-widget',
  templateUrl: './product-widget.component.html',
  styleUrls: ['./product-widget.component.css'],
})
export class ProductWidgetComponent implements OnInit, OnChanges, OnDestroy {
  public editLinkBox = false;
  public editImageBox = false;
  private _widgetDetails: ProductListWidget | undefined;

  @ViewChild('contentBox', { read: ElementRef }) private contentBox:
    | ElementRef
    | undefined;
  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input() set widgetDetails(value: ProductListWidget | undefined) {
    this._widgetDetails = value;
    if (value?.widget) {
      this.setInitialProductsValues(value.widget);
    }
  }

  get widgetDetails(): ProductListWidget | undefined {
    return this._widgetDetails;
  }

  productForm: UntypedFormGroup;
  index: number | null = null;

  private subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    protected ws: WidgetService,
    protected ss: SlugsService,
  ) {
    this.productForm = this.fb.group({
      product_list: this.fb.array([this.createProductGroup()]),
    });
  }

  public get formArrayProductsInformation(): UntypedFormArray {
    return this.productForm.get('product_list') as UntypedFormArray;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetDetails && changes.widgetDetails.currentValue?.widget) {
      this.setInitialProductsValues(changes.widgetDetails.currentValue.widget);
    }
  }

  ngOnInit(): void {
    if (!this.widgetDetails) {
      this.addProductForm();
    }
    this.subscription.add(
      this.productForm.valueChanges.subscribe(() => {
        this.saveProducts();
      }),
    );
  }

  createProductGroup(): FormGroup {
    return this.fb.group({
      img: new FormControl(''),
      price: new FormControl(''),
      title: new FormControl(''),
      description: new FormControl(''),
      button: this.fb.group({
        name: new FormControl(''),
        link: new FormControl(''),
      }),
    });
  }

  saveProducts(): void {
    this.checkButtonFieldsValidity();
    if (this.productForm.valid) {
      const productData = this.setProductData(this.productForm.getRawValue());
      if (this.widgetDetails?.id) {
        this.update.emit(productData);
      } else {
        this.save.emit(productData);
      }
      this.ss.savingWidget = false;
    }
  }

  checkButtonFieldsValidity(): boolean {
    let valid = true;
    this.formArrayProductsInformation.controls.forEach(
      (control: AbstractControl) => {
        const group = control as UntypedFormGroup;
        const buttonGroup = group.get('button') as UntypedFormGroup;

        const nameControl = buttonGroup?.get('name');
        const linkControl = buttonGroup?.get('link');

        if (
          (nameControl?.value && !linkControl?.value) ||
          (!nameControl?.value && linkControl?.value)
        ) {
          valid = false;
          group.setErrors({ buttonFields: true });
        }
      },
    );
    return valid;
  }

  addProductForm(): void {
    this.formArrayProductsInformation.push(this.createProductGroup());
    this.scrollRight();
  }

  scrollRight(): void {
    setTimeout(() => {
      this.contentBox?.nativeElement.scrollTo({
        left: this.contentBox?.nativeElement.scrollLeft + 360,
        behavior: 'smooth',
      });
    }, 0);
  }

  setProductData(
    products: ProductListDetails,
  ): ProductDetails | ProductDetails[] {
    const productsList = this.addHttpsTransferProtocolToButtonLink(
      products.product_list,
    );
    return productsList;
  }

  addHttpsTransferProtocolToButtonLink(
    products: ProductDetails[],
  ): ProductDetails[] {
    const newProductList = [];
    for (const product of products) {
      if (product.button) {
        const { link, name } = product.button;
        const modifiedObject = {
          ...product,
          button: { name, link: validateLinkUrl(link) },
        };
        newProductList.push(modifiedObject);
      }
    }
    return newProductList;
  }

  editButtonLink(index: number): void {
    this.editLinkBox = true;
    this.index = index;
  }

  editImageLink(index: number): void {
    this.editImageBox = true;
    this.index = index;
  }

  setInitialProductsValues(data: ProductDetails[] | ProductDetails): void {
    this.formArrayProductsInformation.clear();
    const products = Array.isArray(data) ? data : [data];
    const productControls = products.map(
      ({ title, description, price, button, img }) =>
        this.fb.group({
          img,
          price,
          title,
          description,
          button: this.fb.group({
            name: button?.name,
            link: button?.link,
          }),
        }),
    );
    this.formArrayProductsInformation.controls = productControls;
  }

  closeBox(): void {
    this.index = null;
    if (this.editImageBox || this.editLinkBox) {
      this.editImageBox = false;
      this.editLinkBox = false;
    }
  }

  closeModalWindow(): void {
    if (this.ss.savingWidget) {
      this.ss.savingWidget = false;
    }
  }

  removeProduct(index: number): void {
    this.formArrayProductsInformation.removeAt(index);
    const productData = this.setProductData(this.productForm.getRawValue());
    if (this.formArrayProductsInformation.length === 0) {
      this.remove.emit(this.widgetDetails);
      this.ws.reset();
    } else if (this.widgetDetails) {
      if (this.widgetDetails?.id) {
        this.update.emit(productData);
      } else {
        this.save.emit(productData);
      }
    }
  }

  removeAll(): void {
    this.remove.emit(this.widgetDetails);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
