import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-checkbox-button',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.css',
})
export class CheckboxComponent {
  @Input() checked: boolean | undefined = false;
  @Output() checkedChange = new EventEmitter<boolean>();

  public toggleChecked() {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
