<div class="message-widget-container">
  <div class="input-inner">
    <div class="required-flex">
      <div class="editor-container">
        <quill-editor
          [formControl]="message"
          placeholder="Enter the message text …"
          [sanitize]="true"
          [modules]="quillModules"
          maxlength="4000"
          #quill
        >
        </quill-editor>
        <div class="remove-button">
          <norby-bin
            (remove)="removeContent()"
            [showBin]="true"
            [item]="'Content'"
          ></norby-bin>
        </div>
      </div>
      <small class="required-asterisk">*</small>
    </div>
    <norby-bin
      class="widget-bin"
      (remove)="removeButton()"
      [showBin]="true"
      [item]="'Widget'"
    ></norby-bin>
  </div>
</div>
<norby-modal
  *ngIf="ss.savingWidget && !message.valid && message.touched"
  title="Fill all required fields."
  (closeModal)="closeModalWindow()"
>
  <div slot="content">
    <div class="modal-content">
      <p *ngIf="!message.get('message')?.value">
        Field "Enter a message …" is required.
      </p>
    </div>
    <button class="main-button" (click)="closeModalWindow()">Got it!</button>
  </div>
</norby-modal>
