<norby-slug-wrapper
  [title]="'Widgets'"
  [subtitle]="'Add widgets to the answer (product cards, buttons).'"
>
  <ng-template #widgetContainer />

  <norby-select
    *ngIf="isSelectEnabled"
    (setChangeValue)="selectWidgetType($event)"
    [placeholder]="'Choose a widget ...'"
    [style]="{ width: '100%' }"
    [isOpenedToTop]="true"
    [optionValues]="widgetOptions$ | async"
    [textColor]="'var(--light-text-color)'"
  ></norby-select>

  <div class="multi-widget-actions">
    <button (click)="addWidgetForm()" class="main-button" type="button">
      Add Widget
    </button>
    <norby-remove-all-bin
      *ngIf="widgetCount > 1"
      [showBin]="true"
      (remove)="removeAllWidgets()"
    />
  </div>
</norby-slug-wrapper>
