<norby-statistic></norby-statistic>

<div class="invite card">
  <h3>Invite your teammates</h3>
  <div class="form-container">
    <norby-input
      label="Teammate username"
      placeholder="Username"
      [control]="getControl('username')"
    >
    </norby-input>
    <norby-input
      label="Teammate work email"
      placeholder="Email Address"
      [control]="getControl('email')"
    >
    </norby-input>
    <norby-input
      label="Teammate first name"
      placeholder="First Name"
      [control]="getControl('firstName')"
    >
    </norby-input>
    <norby-input
      label="Teammate last name"
      placeholder="Last Name"
      [control]="getControl('lastName')"
    ></norby-input>
    <norby-input
      label="Teammate password"
      [eyeIcon]="true"
      type="password"
      placeholder="5+ symbols"
      [minLength]="5"
      [control]="getControl('password')"
      [autocomplete]="autocomplete"
    >
    </norby-input>
    <norby-select
      (setChangeValue)="getOptionValue($event)"
      [label]="'Role'"
      [optionValues]="roleOptions"
      [name]="'Role'"
      [currentValue]="currentValue"
      [control]="getControl('role')"
      [textColor]="'var(--gray76)'"
      [background]="'var(--grayFA)'"
    ></norby-select>
    <norby-accent-button
      [disabled]="!this.form.valid"
      [title]="'Invite'"
      (click)="send()"
      [isLoading]="inviteSending"
      [type]="'button'"
    >
    </norby-accent-button>
  </div>
</div>
<div class="team-list card" *ngIf="agents$ | async as agentProfiles">
  <h3>My Team</h3>
  <norby-spinner *ngIf="loader$ | async"></norby-spinner>
  <div
    class="team-container"
    *ngFor="let agent of agentProfiles; let last = last"
  >
    <div class="agent-information">
      <norby-agent-avatar
        [avatar]="agent.avatar"
        [status]="agent.status"
      ></norby-agent-avatar>
      <div class="ml-fix-position">
        <div class="name">{{ agent.firstName }} {{ agent.lastName }}</div>
        <div class="details">
          {{ agent.role }}
          <span *ngIf="agent.statistic && agent.role === 'Support Agent'">
            <strong>
              &bull; {{ agent.statistic.assignedChat || 0 }} assigned chats
              &bull; {{ agent.statistic.closedChat || 0 }} closed chat &bull;
              {{ agent.statistic.online || 0 | totalMinutesToTimeString }}
              online
            </strong>
            for Today
          </span>
        </div>
      </div>
    </div>
    <norby-three-dots [lastIndex]="last">
      <norby-context-actions
        (optionAction)="isOpenModal = true"
      ></norby-context-actions>
      <norby-choose-actions
        [availableRoles]="roleOptions"
        [role]="agent.role"
        [title]="'Change Role'"
        (selectRole)="changeRole(agent.uid, $event)"
      ></norby-choose-actions>
      <norby-modal
        *ngIf="isOpenModal"
        (closeModal)="closeRemoveWindow()"
        title="'Remove the Teammate?'"
      >
        <div slot="content">
          <norby-attribute-remove-popup
            [cancelAction]="'Cancel'"
            [submitAction]="'Remove'"
            [deleteNotification]="
              'The teammate will lost his access to the admin panel.'
            "
            (closePopup)="closeRemoveWindow()"
            (remove)="removeAgent(agent.uid)"
          />
        </div>
      </norby-modal>
    </norby-three-dots>
  </div>
</div>
<norby-modal
  *ngIf="invitedShow"
  (closeModal)="toggleInvitedShow()"
  title="Invite Was Sent"
>
  <div slot="content">
    <div class="invite-user">
      <div class="modal-text">
        Now the teammate can join by the login data sent to their email.
      </div>
      <button class="main-button" (click)="toggleInvitedShow()">Got It</button>
    </div>
  </div>
</norby-modal>
<norby-modal
  *ngIf="errorModal"
  (closeModal)="errorModal = false"
  title="This user is already exist"
>
  <div slot="content">
    <div class="invite-user">
      <div class="modal-text">
        Choose another username and/or change the email.
      </div>
      <button class="main-button" (click)="errorModal = false">Got it</button>
    </div>
  </div>
</norby-modal>
