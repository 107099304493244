<ng-container *ngIf="ss.selectedSlug$ | async as selectedSlug">
  <norby-slug-name
    [slug]="selectedSlug"
    [disabled]="selectedSlug.approved"
  ></norby-slug-name>
  <div class="textarea-wrapper">
    <norby-slug-textarea [selectedSlug]="selectedSlug" />
  </div>
  <ng-container>
    <div class="down-arrow">
      <norby-down-arrow-button></norby-down-arrow-button>
    </div>
    <norby-automation-widgets />
  </ng-container>
</ng-container>
