import { Locale } from '../Locale';
import { languages } from '../../../../languages';
import { QuillEditorComponent } from 'ngx-quill';

export function initializeLangObject<T>(): Record<Locale, T[]> {
  return languages.reduce(
    (obj, lang) => {
      obj[lang.short_name as Locale] = [];
      return obj;
    },
    {} as Record<Locale, T[]>,
  );
}

export function setFocusAfterContent(
  quill: QuillEditorComponent | undefined,
): void {
  if (quill) {
    const editor = quill.quillEditor;
    if (editor) {
      editor.focus();
      const length = editor.getLength();
      editor.setSelection(length, length);
    }
  }
}
