<norby-navbar></norby-navbar>
<form [formGroup]="resetForm" class="reset-form">
  <h1 class="reset-header">Reset Password</h1>
  <section class="form-contents">
    <label for="email-input">Your work email</label>
    <input
      type="email"
      id="email-input"
      placeholder="Your work email..."
      [formControlName]="'email'"
    />
    <button type="submit" class="main-button" id="send" (click)="send()">
      Send Link
    </button>
  </section>
</form>
<norby-modal
  *ngIf="showModal"
  (closeModal)="gotToLogin()"
  title="The Link Was Sent"
>
  <div slot="content">
    <section id="success">
      <p>Check trash and spam folders if you see no link in your inbox.</p>
      <button
        type="submit"
        class="main-button got-it-button"
        (click)="gotToLogin()"
      >
        Got It
      </button>
    </section>
  </div>
</norby-modal>
