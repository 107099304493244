import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Widget } from '../../../../../../../@core/types/Automations/Widgets/Widget';
import { SlugsService } from '../../../../../../../@core/services/automations/slugs.service';
import { UserLanguagesService } from '../../../../../../../@core/services/user-languages.service';
import { FormDetails } from '../../../../../../../@core/types/Automations/Widgets/FormDetails';

export type FormWidgetInputData = {
  description: string;
  inputs: {
    placeholder: string;
  };
  isMandatory: boolean;
  attribute: string;
};

@Component({
  selector: 'norby-form-widget-input',
  templateUrl: './form-widget-input.component.html',
  styleUrl: './form-widget-input.component.css',
})
export class FormWidgetInputComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  currentLanguage = '';
  public isMandatory = true;
  public attributeValue = '';
  private readonly subscription: Subscription = new Subscription();
  public inputWidgetFormDescription = new FormControl<string>('', {
    validators: [Validators.required],
    nonNullable: true,
  });
  public inputWidgetFormPlaceholder = new FormControl<string>('', {
    validators: [],
    nonNullable: true,
  });
  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input() data: FormWidgetInputData | null = null;
  @Input() isReadOnly: boolean = false;
  @ViewChild('description') description: ElementRef | undefined;

  constructor(
    protected ss: SlugsService,
    private uls: UserLanguagesService,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {
    this.subscription.add(
      this.uls.currentLanguage$.subscribe((lang) => {
        this.currentLanguage = lang;
      }),
    );
  }

  ngOnInit(): void {
    if (this.data) {
      this.inputWidgetFormDescription.setValue(this.data.description);

      if (this.data.inputs) {
        this.inputWidgetFormPlaceholder.setValue(this.data.inputs.placeholder);
      } else {
        this.inputWidgetFormPlaceholder.setValue('');
      }
      this.isMandatory = this.data.isMandatory;
      this.attributeValue = this.data.attribute;
    }

    this.subscription.add(
      this.inputWidgetFormDescription.valueChanges.subscribe(() => {
        this.saveInputFormData();
      }),
    );

    this.subscription.add(
      this.inputWidgetFormPlaceholder.valueChanges.subscribe(() => {
        this.saveInputFormData();
      }),
    );
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.saveInputFormData();
      this.cdr.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetDetails?.currentValue) {
      const widget = changes.widgetDetails.currentValue as Widget;
      const formDetails = widget.widget as FormDetails;

      if (
        !!formDetails.description &&
        !!formDetails.inputs.placeholder &&
        formDetails.isMandatory
      ) {
        this.inputWidgetFormDescription.setValue(formDetails.description);
        this.inputWidgetFormPlaceholder.setValue(formDetails.description);
        this.isMandatory = formDetails.isMandatory;
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (
        this.description &&
        document.activeElement !== this.description.nativeElement &&
        this.description.nativeElement.value === ''
      ) {
        this.description.nativeElement.focus();
      }
    }, 0);
  }

  onBlur() {
    if (this.description) {
      this.description.nativeElement.blur();
    }
  }

  saveInputFormData(): void {
    if (this.inputWidgetFormDescription.valid) {
      const fullData: FormWidgetInputData = {
        description: this.inputWidgetFormDescription.value ?? '',
        inputs: { placeholder: this.inputWidgetFormPlaceholder.value ?? '' },
        isMandatory: this.isMandatory,
        attribute: this.attributeValue,
      };
      if (this.data) {
        this.update.emit(fullData);
      } else {
        this.save.emit(fullData);
      }
    }
  }

  toggleForm(): void {
    this.isMandatory = !this.isMandatory;
    this.saveInputFormData();
  }

  closeModalWindow(): void {
    if (this.ss.savingWidget) {
      this.ss.savingWidget = false;
    }
  }

  removeFormInput(): void {
    this.remove.emit();
  }

  public onSelectChange(attributeName: string): void {
    this.attributeValue = attributeName;
    this.saveInputFormData();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
