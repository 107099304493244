import {
  AfterViewInit,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Widget } from '../../../../../@core/types/Automations/Widgets/Widget';
import { SelectOption } from '../../../../select/select.component';
import { FormWidgetInputComponent } from './components/form-widget-input/form-widget-input.component';
import { FormsTypeWidget } from './types/FormsTypeWidget';
import { FormWidgetSelectComponent } from './components/form-widget-select/form-widget-select.component';
import { FormWidgetCheckboxComponent } from './components/form-widget-checkbox/form-widget-checkbox.component';
import { FormWidgetRadioComponent } from './components/form-widget-radio/form-widget-radio.component';
import { enabledFormsWidgets } from './constants/enabledFormsWidgets';
import {
  FormsComponentsRef,
  FormsWidgetData,
  FormsWidgetDataType,
} from './types';

@Component({
  selector: 'norby-form-widget-base',
  styleUrls: ['./form-widget-base.component.css'],
  templateUrl: './form-widget-base.component.html',
})
export class FormWidgetBaseComponent implements AfterViewInit {
  @Output() save = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Input() widgetDetails: Widget | undefined;

  public formsWidgetData: Array<FormsWidgetData> = [];
  public uniqueId = 0;
  private componentRefs: { [key: string]: ComponentRef<FormsComponentsRef> } =
    {};

  @ViewChild('container', { read: ViewContainerRef })
  container!: ViewContainerRef;

  public selectOptions: SelectOption[] = [];
  public currentValue: string = '';

  constructor() {
    this.selectOptions = enabledFormsWidgets.map((el) => ({
      key: el.toLowerCase(),
      value: el,
    }));
  }

  ngAfterViewInit(): void {
    this.initializeUniqueId();
    this.initializeComponentsFromData();
  }

  private initializeComponentsFromData(): void {
    if (this.widgetDetails && Array.isArray(this.widgetDetails.widget)) {
      this.widgetDetails.widget.forEach((widget: FormsWidgetData) => {
        const componentType = this.getComponentType(widget.type);
        if (componentType) {
          this.createComponent(componentType, widget);
        }
      });
    }
  }

  public addComponent(data: SelectOption): void {
    const widgetType = data.value as FormsTypeWidget;
    const componentType = this.getComponentType(widgetType);

    if (componentType) {
      this.createComponent(componentType, {
        id: this.generateUniqueId(),
        type: widgetType,
        data: null,
      });
    }
    this.currentValue = '';
  }

  private getComponentType(
    widgetType: FormsTypeWidget,
  ): Type<FormsComponentsRef> | null {
    switch (widgetType) {
      case FormsTypeWidget.INPUT:
        return FormWidgetInputComponent;
      case FormsTypeWidget.SELECT:
        return FormWidgetSelectComponent;
      case FormsTypeWidget.RADIO:
        return FormWidgetRadioComponent;
      case FormsTypeWidget.CHECKBOX:
        return FormWidgetCheckboxComponent;
      default:
        console.warn(`Unknown widget type selected: ${widgetType}`);
        return null;
    }
  }

  private createComponent(
    component: Type<FormsComponentsRef>,
    widgetData: FormsWidgetData,
  ): void {
    const componentRef = this.container.createComponent(component);
    const { id, type, data } = widgetData;

    this.addNewFormData(id, type, data);
    this.componentRefs[id] = componentRef;

    componentRef.instance.data = data;

    componentRef.instance.save.subscribe((updatedData) => {
      this.handleSaveFromChild(id, updatedData);
    });

    componentRef.instance.update.subscribe((updatedData) => {
      this.handleSaveFromChild(id, updatedData);
    });

    componentRef.instance.remove.subscribe(() => {
      this.removeComponent(id);
    });
  }

  private addNewFormData(
    id: string,
    type: FormsTypeWidget,
    data?: FormsWidgetDataType,
  ): void {
    this.formsWidgetData.push({ id, type, data: data || null });
  }

  private handleSaveFromChild(id: string, data: FormsWidgetDataType): void {
    const existingIndex = this.formsWidgetData.findIndex(
      (item) => item.id === id,
    );
    if (existingIndex !== -1) {
      this.formsWidgetData[existingIndex].data = data;
    }
    this.save.emit(this.formsWidgetData);
  }

  private removeComponent(id: string): void {
    this.formsWidgetData = this.formsWidgetData.filter(
      (item) => item.id !== id,
    );

    const componentRef = this.componentRefs[id];
    if (componentRef) {
      componentRef.destroy();
      delete this.componentRefs[id];
    }
    this.save.emit(this.formsWidgetData);
  }

  public requestRemove(): void {
    if (this.widgetDetails) {
      this.remove.emit(this.widgetDetails);
    }
  }

  private generateUniqueId(): string {
    return `form-widget-${Date.now()}-${Math.random().toString(36)}`;
  }

  private initializeUniqueId(): void {
    const maxId = this.formsWidgetData.reduce((max, item) => {
      const idNum = parseInt(item.id.split('-')[2], 10);
      return idNum > max ? idNum : max;
    }, 0);
    this.uniqueId = maxId + 2;
  }
}
