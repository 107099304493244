<!--Fourth Step-->

<norby-navbar></norby-navbar>
<div class="notifier">
  <h2>Verify Your Email</h2>
  <p>
    Please check your email and click on the verification link to complete your
    registration.
  </p>
  <button
    class="main-button"
    (click)="resendLink()"
    [disabled]="linkHasBeenSend"
  >
    Re-send Link
  </button>
</div>
<norby-modal
  *ngIf="obs.verificationLinkHasBeenReSend"
  title="The Link Was Sent"
  (closeModal)="closeModal()"
>
  <div slot="content">
    <p class="modal-content">
      Check trash and spam folders if you see no link in your inbox.
    </p>
    <button
      type="submit"
      class="main-button got-it-button"
      (click)="closeModal()"
    >
      Got It
    </button>
  </div>
</norby-modal>
