<button
  type="button"
  (click)="fireRemove($event)"
  *ngIf="visible"
  class="wrapper"
  [style]="{ top: top, right: right }"
  [disabled]="disabled"
>
  <svg
    class="inner-svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.3334 8.99984C17.3334 13.6022 13.6025 17.3332 9.00008 17.3332C4.39771 17.3332 0.666748 13.6022 0.666748 8.99984C0.666748 4.39746 4.39771 0.666504 9.00008 0.666504C13.6025 0.666504 17.3334 4.39746 17.3334 8.99984ZM7.03039 5.96955C6.7375 5.67665 6.26262 5.67665 5.96973 5.96955C5.67684 6.26244 5.67684 6.73731 5.96973 7.03021L7.9394 8.99988L5.96973 10.9695C5.67684 11.2624 5.67684 11.7373 5.96973 12.0302C6.26262 12.3231 6.7375 12.3231 7.03039 12.0302L9.00006 10.0605L10.9697 12.0302C11.2626 12.3231 11.7375 12.3231 12.0304 12.0302C12.3233 11.7373 12.3233 11.2624 12.0304 10.9695L10.0607 8.99988L12.0304 7.03021C12.3233 6.73731 12.3233 6.26244 12.0304 5.96955C11.7375 5.67665 11.2626 5.67665 10.9697 5.96955L9.00006 7.93922L7.03039 5.96955Z"
      [attr.fill]="disabled ? 'var(--gray76)' : 'black'"
    />
  </svg>
</button>

<!-- Modal Window -->
<norby-modal
  *ngIf="showModal"
  [title]="'Remove the ' + item + '?'"
  (closeModal)="cancelRemove()"
>
  <div slot="content">
    <p class="modal-content">
      You are going to remove this {{ item }}. Are you sure?
    </p>
    <div class="modal-flex">
      <button class="secondary-button" (click)="cancelRemove()">Cancel</button>
      <button class="main-button" (click)="confirmRemove()">Remove</button>
    </div>
  </div>
</norby-modal>
