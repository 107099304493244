<ng-container>
  <div class="input-form">
    <norby-switcher
      *ngIf="isVisible"
      (toggle)="toggleMandatory.emit()"
      [label]="'Mandatory'"
      [switcherPosition]="'right'"
      [switchedOn]="isMandatory"
    ></norby-switcher>
    <ng-content select="[slot=content]" />
  </div>
</ng-container>
