import { Injectable } from '@angular/core';
import { ProjectSettings } from '../../domain/ProjectSettings';
import { Limits } from '../../types/Automations/Limits';
import { PromoCode } from '../../types/Automations/PromoCode';
import { BillingAddressResponse } from '../../types/BillingAddressResponse';
import { ProjectSettingsResponse } from '../../types/ProjectSettingsResponse';
import { environment } from '../../../../environments/environment';
import { CompanyDetailsResponse } from '../../types/CompanyDetailsResponse';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectSettingsService {
  public referralLink$ = new BehaviorSubject<string>('');
  public projectSettings: ProjectSettings;

  constructor() {
    this.projectSettings = new ProjectSettings();
  }

  updateValues(result: ProjectSettingsResponse): void {
    this.projectSettings.apiKey$.next(result.api_secret);
    this.projectSettings.projectToken$.next(result.project_token);
    this.projectSettings.telegramWebhook$.next(
      `${environment.endpoint}/api/public/chat/newTelegramMessage/${result.project_token}`,
    );
    if (result.discord_application_public_key) {
      this.projectSettings.discordWebhook$.next(
        `${environment.endpoint}/api/discord/${result.project_token}/${result.discord_application_public_key}`,
      );
    } else {
      this.projectSettings.discordWebhook$.next('Discord not connected');
    }
    this.projectSettings.slugToken$.next(result.slug_token);
    this.projectSettings.locale$.next(result.locale);
    const referralUrl = `${environment.baseUrl}/signup?utm_id=NBAFF_${result.project_token}`;
    this.referralLink$.next(referralUrl);
  }

  getAutomationLimits(limits: Limits): void {
    this.projectSettings.automationLimits$.next(limits);
  }

  getPromoCodes(promoCode: PromoCode): void {
    this.projectSettings.promoCode$.next(promoCode);
  }

  getUserBillingAddress(address: BillingAddressResponse): void {
    this.projectSettings.userBillingAddress$.next(address);
  }

  getCompanyDetails(data: CompanyDetailsResponse): void {
    this.projectSettings.companyDetails$.next(data);
  }
}
