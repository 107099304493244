import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export type SelectOption = {
  key: number | string;
  value: string;
  isChecked?: boolean;
};

@Component({
  selector: 'norby-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectComponent {
  @Input() optionValues: SelectOption[] | null = [];
  @Input() optionsLabel = '';
  @Input() label = '';
  @Input() subLabel = '';
  @Input() name = 'This field';
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder = '';
  @Input() currentValue: string | undefined = '';
  @Input() textColor = '';
  @Input() arrowColor = 'black';
  @Input() arrowBgColor = 'var(--grayF1)';
  @Input() background = '';
  @Input() isOpenedToTop: boolean = false;
  @Input() isRemoveItemAllowed: boolean = false;
  @Input() isResetValueAfterChange: boolean = false;
  @Output() setChangeValue = new EventEmitter();
  @Output() removeItem = new EventEmitter();
  public isOpened = false;

  constructor(private ref: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.isOpened = false;
    }
  }

  public toggle(): void {
    this.isOpened = !this.isOpened;
  }

  public setValue({ key, value }: SelectOption): void {
    this.currentValue = value;
    this.setChangeValue.emit({ key, value });
    this.isOpened = false;

    if (this.isResetValueAfterChange) {
      this.currentValue = '';
    }
  }
}
