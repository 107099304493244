import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { GetAccessToken, GetMerchantApi } from '../services/auth.service';
import { ChatService } from '../services/chat.service';
import { TitleNotificationService } from '../services/title-notification.service';
import { ApiFile } from '../types/ApiFile';
import ChatMessage from '../types/ChatMessage';
import { ChatMessageConfirmResponse } from '../types/ChatMessageConfirmResponse';
import ChatMessageDto from '../types/ChatMessageDto';
import { ImageInfo } from '../types/ImageInfo';
import Participant from '../types/Participant';
import { Profile } from './Profile';

export default class Chat {
  messages: ChatMessage[];
  selected = false;
  viewed$ = new BehaviorSubject<boolean>(false);
  online$ = new BehaviorSubject<boolean>(false);
  email$ = new BehaviorSubject<string>('');
  name$ = new BehaviorSubject<string>('');
  lastMessage$: BehaviorSubject<ChatMessage | undefined> = new BehaviorSubject<
    ChatMessage | undefined
  >(undefined);

  private chatSource: Socket | undefined;
  private delayTimer: number | undefined;
  private typingSubject = new BehaviorSubject<boolean>(false);

  constructor(
    public mine: boolean,
    public open: boolean,
    private zone: NgZone,
    messages: ChatMessage[],
    public uid: string,
    public id: string,
    public clientName: string,
    public clientEmail: string | undefined,
    lastMessage: ChatMessage | undefined,
    public unreadMessage: boolean = true, // @TODO probably remove it
    public is_client_online: boolean = false,
    viewed: boolean,
    private ts: TitleNotificationService,
    public participant: Participant,
    private unreadMessageCount: ChatService['unreadMessagesTotal$'],
  ) {
    if (open) {
      this.connect();
    }

    this.messages = [...messages];
    this.lastMessage$.next(lastMessage);
    this.online$.next(is_client_online);
    this.email$.next(clientEmail || '');
    this.viewed$.next(viewed);
    this.selected = false;
    this.name$.next(clientName || 'Copybara');
  }

  public connect() {
    const socketUrl = `${environment.endpoint}/room`;
    if (!this.chatSource) {
      this.chatSource = io(socketUrl, {
        query: {
          uuid: this.uid,
          token: GetAccessToken(),
          apiKey: GetMerchantApi(),
        },
        forceNew: true,
        transports: ['websocket', 'polling'],
      });
    }
    this.setSocketEvents();
  }

  private setSocketEvents() {
    if (this.chatSource !== undefined) {
      this.chatSource.on('message', (event: { message: ChatMessageDto }) => {
        const { message } = event;
        if (!message || message.author !== ('client' || 'bot')) {
          return;
        }

        this.zone.run(() => {
          const chatMessage: ChatMessage = {
            author: message.author,
            id: message.id,
            message: message.message,
            time: message.time,
            full_date: message.full_date,
            showAvatar: message.showAvatar,
            agent: message.agent ? new Profile(message.agent) : undefined,
            type: message.type,
            image: new BehaviorSubject<ImageInfo | undefined>(message.image),
            imageFile: message.imageFile,
            confirmed: new BehaviorSubject<boolean>(true),
            errorMessage: new BehaviorSubject<string>(message.errorMessage),
          };

          this.adjustOnlineStatus(chatMessage.author);
          this.addMessage(chatMessage);
          if (
            chatMessage.author === 'client' &&
            (!this.selected || (this.selected && !this.ts.inBrowserTab))
          ) {
            this.viewed$.next(false);
            if (!this.ts.inProgress) {
              this.ts.start();
            }
          }
        });
      });

      this.chatSource.on('updateImage', (event: string) => {
        const { messageId, imageInfo } = JSON.parse(event);
        this.zone.run(() => this.updateMessage(messageId, imageInfo));
      });

      this.chatSource.on('imageError', (event: string) => {
        const { messageId, error = 'Unknown error' } = JSON.parse(event);
        this.zone.run(() => this.removeMessage(messageId, error));
      });

      this.chatSource.on('typing', (data: { author: string } | undefined) => {
        this.zone.run(() => {
          if (data && data.author === 'client') {
            this.typingSubject.next(true);
          }
        });
      });

      this.chatSource.on(
        'typingEnded',
        (data: { author: string } | undefined) => {
          this.zone.run(() => {
            if (data && data.author === 'client') {
              this.typingSubject.pipe(debounceTime(700)).subscribe(() => {
                this.typingSubject.next(false);
              });
            }
          });
        },
      );

      this.chatSource.on('clientEmail', (event: { message: string }) => {
        this.zone.run(() => {
          this.clientEmail = event.message;
          this.email$.next(event.message);
        });
      });

      this.chatSource.on('clientName', (event: { message: string }) => {
        this.zone.run(() => {
          this.clientName = event.message;
          this.name$.next(event.message);
        });
      });

      this.chatSource.on('clientOnline', (event: { uuid: string }) => {
        this.zone.run(() => {
          if (event.uuid === this.participant.uid) {
            this.online$.next(true);
          }
        });
      });

      this.chatSource.on('clientOffline', (event: { uuid: string }) => {
        this.zone.run(() => {
          if (event.uuid === this.participant.uid) {
            this.online$.next(false);
          }
        });
      });
    }
  }

  public typingObservable = this.typingSubject.asObservable();

  get socketConnected(): boolean {
    return this.chatSource !== undefined;
  }

  get showConnectionError(): boolean {
    return this.open && this.chatSource === undefined;
  }

  static setupFlags(
    current: ChatMessage,
    index: number,
    array: ChatMessage[],
  ): ChatMessage {
    if (index < 1) {
      return { ...current, showAvatar: true };
    }
    const prev = array[index - 1];

    if (prev.author !== current.author) {
      return { ...current, showAvatar: true };
    }

    if (prev?.agent?.uid !== current?.agent?.uid) {
      return { ...current, showAvatar: true };
    }

    return { ...current, showAvatar: false };
  }

  public async sendMessage(
    message: ChatMessage,
    callback: (result: ChatMessageConfirmResponse) => void,
  ): Promise<void> {
    if (!this.chatSource || !this.chatSource.connected) {
      console.error('No chat source, or socket is not connected!');
      return;
    }

    this.chatSource.emit(
      'newMessage',
      {
        message: message.message,
        messageType: message.type,
        messageAuthor: message.author,
        file: message.imageFile
          ? await this.makeApiFile(message.imageFile)
          : undefined,
      },
      callback,
    );
  }

  public async sendEmailMessage(
    message: ChatMessage,
    callback: (result: { status: string }) => void,
  ): Promise<void> {
    if (!this.chatSource || !this.chatSource.connected) {
      console.error('No chat source, or socket is not connected!');
      return;
    }

    this.chatSource.emit(
      'emailMessage',
      {
        message: message.message,
        messageType: message.type,
        messageAuthor: message.author,
        file: message.imageFile
          ? await this.makeApiFile(message.imageFile)
          : undefined,
      },
      callback,
    );
  }

  public setViewed(): void {
    this.viewed$.next(true);
    if (this.unreadMessageCount.value === 0) {
      this.ts.stop();
    }
  }

  public emitTyping(): void {
    this.emitStartTyping();
    if (this.delayTimer) {
      window.clearTimeout(this.delayTimer);
    }

    this.delayTimer = window.setTimeout(() => this.emitEndTyping(), 500);
  }

  public sendImageError(messageId: string, error: string): void {
    this.chatSource?.emit('imageError', messageId, error);
  }

  public removeMessage(messageId: string, error: string): void {
    this.messages = this.messages.filter((m) => m.id !== messageId);
    this.sendImageError(messageId, error);
  }

  public addMessage(message: ChatMessage): void {
    this.messages.push({
      ...message,
      showAvatar: this.shouldShowAvatar(message.author, message.agent),
    });
    if (message.type !== 'new_chat_button') {
      this.lastMessage$.next(message);
    }
    if (message.author === 'client') {
      this.setViewed();
    }
  }

  public updateMessage(messageId: string, imageInfo: ImageInfo): void {
    const message = this.messages.find((m) => m.id === messageId);
    if (message) {
      message.image?.next(imageInfo);
    }
  }

  private emitStartTyping(): void {
    if (this.chatSource !== undefined) {
      this.chatSource.emit('typing', { author: 'agent' });
    }
  }

  private emitEndTyping(): void {
    if (this.chatSource !== undefined) {
      this.chatSource.emit('typingEnded', { author: 'agent' });
    }
  }

  private adjustOnlineStatus(author: string): void {
    if (!this.is_client_online && author === 'client') {
      this.online$.next(true);
    }
  }

  private shouldShowAvatar(
    author: string,
    agent: Profile | undefined,
  ): boolean {
    const length = this.messages.length;
    const last: ChatMessage | undefined = length
      ? this.messages[length - 1]
      : undefined;
    if (length) {
      return last?.author !== author;
    }
    if (last && last.agent) {
      return last.agent && last.agent.uid !== agent?.uid;
    }
    return false;
  }

  private async makeApiFile(file: File): Promise<ApiFile> {
    return {
      buffer: this.toBuffer(await file.arrayBuffer()),
      destination: '',
      encoding: '',
      fieldname: 'file',
      filename: file.name,
      mimetype: file.type,
      originalname: file.name,
      path: `/tmp/${file.name}`,
      size: file.size,
    };
  }

  private toBuffer(ab: ArrayBuffer): Buffer {
    const buf = Buffer.alloc(ab.byteLength);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; ++i) {
      buf[i] = view[i];
    }
    return buf;
  }
}
