import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-form-widget-container',
  templateUrl: './form-widget-container.component.html',
  styleUrl: './form-widget-container.component.css',
})
export class FormWidgetContainerComponent {
  @Input() isMandatory: boolean = false;
  @Input() isVisible: boolean = false;
  @Output() toggleMandatory = new EventEmitter();
}
