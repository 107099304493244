import { Component, EventEmitter, Input, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { ChatSearchStateService } from '../../../../../../../@core/services/chat-search-state.service';

@Component({
  selector: 'norby-full-attributes-select',
  templateUrl: './full-attributes-select.component.html',
})
export class FullAttributesSelectComponent {
  @Input() currentValue: string = '';
  @Output() selectChange = new EventEmitter();
  chatAttributeNames$ = this.as.chatAttributes$.pipe(
    map((attributes) => attributes.map((a) => a.name)),
  );
  userAttributeNames$ = this.as.userAttributes$.pipe(
    map((attributes) => attributes.map((a) => a.name)),
  );

  constructor(protected as: ChatSearchStateService) {}

  public selectAttribute(name: string): void {
    this.selectChange.emit(name);
  }
}
