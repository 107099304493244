import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SelectOption } from '../../../../../../select/select.component';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

export type FormWidgetSelectData = {
  options: SelectOption[];
  label: string;
  isMandatory: boolean;
  attribute: string;
};

@Component({
  selector: 'norby-form-widget-select',
  templateUrl: './form-widget-select.component.html',
  styleUrls: ['./form-widget-select.component.css'],
})
export class FormWidgetSelectComponent implements OnInit, OnDestroy {
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() save = new EventEmitter<FormWidgetSelectData>();
  @Input() data: FormWidgetSelectData | null = null;

  public isOpen: boolean = false;
  public selectWidgetFormLabel = new FormControl<string>('', {
    validators: [Validators.required],
    nonNullable: true,
  });
  public selectValues: SelectOption[] = [];
  public isMandatory = true;
  public attributeValue = '';
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {
    this.subscription.add(
      this.selectWidgetFormLabel.valueChanges.subscribe(() => {
        this.saveSelectFormData();
      }),
    );
  }

  ngOnInit(): void {
    if (this.data) {
      this.selectWidgetFormLabel.setValue(this.data.label, {
        emitEvent: false,
      });
      this.selectValues = [...this.data.options];
      this.isMandatory = this.data.isMandatory;
      this.attributeValue = this.data.attribute;

      this.cdr.detectChanges();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.saveSelectFormData();
    }
  }

  private saveSelectFormData(): void {
    this.save.emit({
      label: this.selectWidgetFormLabel.value || '',
      isMandatory: this.isMandatory,
      attribute: this.attributeValue,
      options: this.selectValues,
    });
  }

  public addOption(value: string): void {
    this.selectValues?.push({
      key: value.toLowerCase(),
      value: value.charAt(0).toUpperCase() + value.slice(1),
    });
  }

  public removeSelectValue($event: SelectOption): void {
    if (!this.selectValues) return;

    const index = this.selectValues.findIndex(
      (item) => item.key === $event.key && item.value === $event.value,
    );

    if (index !== -1) {
      this.selectValues.splice(index, 1);
    }
  }

  public removeFormInput(): void {
    this.remove.emit();
  }

  public toggleForm(): void {
    this.isMandatory = !this.isMandatory;
    this.saveSelectFormData();
  }

  public onSelectChange(attributeName: string): void {
    this.attributeValue = attributeName;
    this.saveSelectFormData();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
