<ng-container>
  <norby-form-widget-container
    [isVisible]="true"
    [isMandatory]="isMandatory"
    (toggleMandatory)="toggleForm()"
  >
    <div slot="content" class="input-inner">
      <div class="input-form checkbox-form__container">
        <div class="input-inner checkbox-form__inner">
          <div class="checkbox-form__content">
            <div class="required-flex" [ngStyle]="{ 'margin-bottom': '20px' }">
              <input
                [formControl]="checkboxWidgetFormLabel"
                [ngClass]="{
                  'button-input': true,
                  'input-description': true,
                  'input-description--error':
                    checkboxWidgetFormLabel.invalid &&
                    checkboxWidgetFormLabel.touched,
                }"
                type="text"
                placeholder="Enter a checkbox buttons title …"
              />
              <small
                *ngIf="isMandatory"
                [ngStyle]="{ position: 'absolute', top: '0', right: '-10px' }"
                class="required-asterisk"
              >
                *
              </small>
              <div
                *ngIf="
                  checkboxWidgetFormLabel.invalid &&
                  checkboxWidgetFormLabel.touched
                "
                class="error-message"
              >
                Checkbox label is required.
              </div>
            </div>
            <div class="widget-input">
              <norby-additional-items-button
                [placeholder]="'Add checkbox button'"
                (addItem)="addCheckbox($event)"
              />
            </div>
          </div>
          <div class="checkbox__container">
            <ng-container *ngFor="let checkbox of checkboxButtons; index as i">
              <div class="checkbox__wrapper">
                <norby-checkbox-button
                  [checked]="checkbox.isChecked"
                  (checkedChange)="onCheckboxClick($event, i)"
                />
                <p class="checkbox__label">
                  {{
                    checkbox.value.charAt(0).toUpperCase() +
                      checkbox.value.slice(1)
                  }}
                </p>
                <norby-bin [showBin]="true" (remove)="removeItem(i)" />
              </div>
            </ng-container>
          </div>
        </div>
        <norby-bin
          (remove)="removeCheckboxFormInput()"
          [showBin]="true"
          [item]="'Widget'"
        ></norby-bin>
      </div>
      <norby-full-attributes-select
        [currentValue]="attributeValue"
        (selectChange)="onSelectChange($event)"
      />
    </div>
  </norby-form-widget-container>
</ng-container>
