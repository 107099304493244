<ng-container>
  <norby-form-widget-container
    [isVisible]="true"
    [isMandatory]="isMandatory"
    (toggleMandatory)="toggleForm()"
  >
    <div slot="content">
      <div class="input-inner">
        <div class="required-flex" [ngStyle]="{ 'margin-right': '16px' }">
          <input
            [formControl]="selectWidgetFormLabel"
            [ngClass]="{
              'button-input': true,
              'input-description': true,
              'input-description--error':
                selectWidgetFormLabel.invalid && selectWidgetFormLabel.touched,
            }"
            type="text"
            placeholder="Enter a label …"
          />
          <small *ngIf="isMandatory" class="required-asterisk">*</small>
          <div
            *ngIf="
              selectWidgetFormLabel.invalid && selectWidgetFormLabel.touched
            "
            class="error-message"
          >
            Select label is required.
          </div>
        </div>
        <div class="widget-input">
          <norby-select
            class="widget-select"
            [optionValues]="selectValues ? selectValues : []"
            [isOpenedToTop]="true"
            [isRemoveItemAllowed]="true"
            [currentValue]="
              selectValues && selectValues.length > 0
                ? selectValues[0].value
                : ''
            "
            (removeItem)="removeSelectValue($event)"
          />
          <norby-bin
            (remove)="removeFormInput()"
            [showBin]="true"
            [item]="'Widget'"
          />
        </div>
      </div>
      <div [ngStyle]="{ 'margin-top': '20px' }">
        <norby-additional-items-button
          [placeholder]="'Add option...'"
          (addItem)="addOption($event)"
        />
        <norby-full-attributes-select
          [currentValue]="attributeValue"
          (selectChange)="onSelectChange($event)"
        />
      </div>
    </div>
  </norby-form-widget-container>
</ng-container>
