export const newChatButtonValues = {
  en: 'Start New Chat',
  ru: 'Начать новый чат',
  ee: 'Alusta Uut Vestlust',
  it: 'Avvia una nuova chat',
  fr: 'Démarrer une nouvelle discussion',
  de: 'Neuen Chat starten',
  ua: 'Розпочати новий чат',
  es: 'Iniciar nuevo chat',
  fi: 'Aloita uusi chat',
  sv: 'Starta ny chatt',
  da: 'Start ny chat',
  no: 'Start ny chat',
  pl: 'Rozpocznij nowy czat',
  pt: 'Iniciar novo bate-papo',
  tr: 'Yeni Sohbet Başlat',
  zh: '開始新聊天',
  ja: '新しいチャットを開始する',
  ar: 'ابدأ محادثة جديدة',
};
