<norby-attributes-header
  [attributeHeaderTitle]="attributeHeaderTitle"
  [attributeHeaderDescription]="attributeHeaderDescription"
  (openAddAttributeModal)="createAttribute = true"
></norby-attributes-header>
<norby-spinner *ngIf="loader$ | async"></norby-spinner>
<div
  class="empty-attributes-list"
  *ngIf="(userAttributes$ | async)?.length === 0"
>
  No attributes to show
</div>
<div *ngFor="let attribute of userAttributes$ | async">
  <norby-attribute
    [name]="attribute.name"
    [description]="attribute.description"
    [attributeUuid]="attribute.uuid"
    [createdAt]="attribute.created_at"
    [updatedAt]="attribute.updated_at"
    (showDeleteModalWindow)="getCurrentUuid($event)"
    (updateAttributeName)="updateUserAttributeTitle($event)"
    (updateAttributeDescription)="updateUserAttributeDescription($event)"
  ></norby-attribute>
</div>
<norby-modal
  *ngIf="createAttribute"
  (closeModal)="createAttribute = false"
  [title]="'Add an Attribute'"
>
  <div slot="content">
    <norby-attribute-add-popup
      [addAttributePopupDescription]="
        'After creating, a new attribute will be available for developers to gather data'
      "
      (openAddAttributeModal)="createAttribute = false"
      (createAttribute)="addUserAttribute($event)"
    />
  </div>
</norby-modal>
<norby-modal
  *ngIf="showDeleteModal"
  (closeModal)="showDeleteModal = false"
  [title]="'Delete the Attribute?'"
>
  <div slot="content">
    <norby-attribute-remove-popup
      [cancelAction]="'Cancel'"
      [submitAction]="'Delete'"
      [deleteNotification]="
        'This attribute will be deleted from all users. Developers will not be able to use it anymore.'
      "
      (remove)="deleteUserAttribute()"
      (openRemoveAttributeModal)="showDeleteModal = false"
      (closePopup)="showDeleteModal = false"
    />
  </div>
</norby-modal>
