<ng-container>
  <norby-form-widget-container
    [isMandatory]="isMandatory"
    [isVisible]="true"
    (toggleMandatory)="toggleForm()"
  >
    <div slot="content" class="input-inner">
      <div class="form-input-wrapper">
        <div class="required-flex">
          <input
            #description
            [formControl]="inputWidgetFormDescription"
            [ngClass]="{
              'button-input': true,
              'input-description': true,
              'input-description--error':
                inputWidgetFormDescription.invalid &&
                inputWidgetFormDescription.touched,
            }"
            type="text"
            placeholder="Enter a description …"
            [disabled]="isMandatory"
            (blur)="onBlur()"
          />
          <small *ngIf="isMandatory" class="required-asterisk">*</small>
        </div>
        <div
          *ngIf="
            inputWidgetFormDescription.invalid &&
            inputWidgetFormDescription.touched
          "
          class="error-message"
        >
          Description is required.
        </div>
        <div class="widget-input">
          <input
            [formControl]="inputWidgetFormPlaceholder"
            [placeholder]="
              inputWidgetFormPlaceholder.value || 'Your placeholder ...'
            "
            [ngClass]="{
              'button-input': true,
              'input-description': true,
              'input-description--error':
                inputWidgetFormPlaceholder.invalid &&
                inputWidgetFormPlaceholder.touched,
            }"
            type="text"
          />
          <norby-bin
            (remove)="removeFormInput()"
            [showBin]="true"
            [item]="'Widget'"
          ></norby-bin>
        </div>
      </div>
      <norby-full-attributes-select
        [currentValue]="attributeValue"
        (selectChange)="onSelectChange($event)"
      />
    </div>
  </norby-form-widget-container>
</ng-container>
<norby-modal
  *ngIf="
    ss.savingWidget &&
    !inputWidgetFormDescription.valid &&
    inputWidgetFormDescription.touched
  "
  title="Fill all required fields."
  (closeModal)="closeModalWindow()"
>
  <div slot="content">
    <div class="modal-content">
      <p *ngIf="!inputWidgetFormDescription?.value">
        Field "Enter a description …" is required.
      </p>
    </div>
    <button class="main-button" (click)="closeModalWindow()">Got it!</button>
  </div>
</norby-modal>
