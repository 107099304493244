import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SelectOption } from '../../../../../../select/select.component';

export type FormWidgetRadioData = {
  label: string;
  options: Array<SelectOption>;
  isMandatory: boolean;
  attribute: string;
};

@Component({
  selector: 'norby-form-widget-radio',
  templateUrl: './form-widget-radio.component.html',
  styleUrls: ['./form-widget-radio.component.css'],
})
export class FormWidgetRadioComponent implements OnInit, OnDestroy {
  @Output() save = new EventEmitter<FormWidgetRadioData>();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input() data: FormWidgetRadioData | null = null;

  public isMandatory = true;
  public attributeValue = '';

  public radioWidgetFormLabel = new FormControl<string | null>('', {
    validators: [Validators.required],
    nonNullable: false,
  });

  private readonly subscription: Subscription = new Subscription();
  public radioButtons: Array<SelectOption> = [];

  constructor(private cdr: ChangeDetectorRef) {
    this.subscription.add(
      this.radioWidgetFormLabel.valueChanges.subscribe(() => {
        this.emitRadioData();
      }),
    );
  }

  ngOnInit(): void {
    if (this.data) {
      this.radioWidgetFormLabel.setValue(this.data.label, {
        emitEvent: false,
      });
      this.radioButtons = [...this.data.options];
      this.isMandatory = this.data.isMandatory;
      this.attributeValue = this.data.attribute;

      this.cdr.detectChanges();
    }
  }

  public addRadio(event: string): void {
    this.radioButtons.push({
      key: event.toLowerCase(),
      value: event.charAt(0).toUpperCase() + event.slice(1),
    });
    this.emitRadioData();
  }

  public removeItem(index: number): void {
    this.radioButtons.splice(index, 1);
    this.emitRadioData();
  }

  public onRadioClick($event: boolean, index: number): void {
    this.radioButtons[index].isChecked = $event;
  }

  private emitRadioData(): void {
    this.save.emit({
      label: this.radioWidgetFormLabel.value || '',
      isMandatory: this.isMandatory,
      attribute: this.attributeValue,
      options: this.radioButtons,
    });
  }

  public removeRadioFormInput(): void {
    this.remove.emit();
  }

  public toggleForm(): void {
    this.isMandatory = !this.isMandatory;
    this.emitRadioData();
  }

  public onSelectChange(attributeName: string): void {
    this.attributeValue = attributeName;
    this.emitRadioData();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
