<div class="modal-mask">
  <div class="modal-container">
    <div class="modal-header">
      <norby-close-icon
        class="close-modal-button"
        (click)="closeModal.emit()"
      ></norby-close-icon>
    </div>
    <h4 *ngIf="title" class="modal-title">{{ title }}</h4>
    <ng-content select="[slot=content]"> </ng-content>
  </div>
</div>
