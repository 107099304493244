<div class="form-widget-base__container">
  <ng-container #container />

  <div class="form-base__container">
    <norby-select
      class="form-base__select"
      [isOpenedToTop]="true"
      [isResetValueAfterChange]="true"
      [optionValues]="selectOptions"
      [placeholder]="'Choose a form element ↑'"
      (setChangeValue)="addComponent($event)"
    />
    <norby-bin (remove)="requestRemove()" [showBin]="true" [item]="'Widget'" />
  </div>
</div>
