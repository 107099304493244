import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { Widget } from '../../../../../@core/types/Automations/Widgets/Widget';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PredefinedAnswersRequestData } from './types';
import { Subscription } from 'rxjs';
import { validateLinkUrl } from '../../../../../@core/validators/linkValidator';

@Component({
  selector: 'norby-answers-widget',
  templateUrl: './answers-widget.component.html',
  styleUrls: ['./answers-widget.component.css'],
})
export class AnswersWidgetComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() widgetDetails: Widget | undefined;
  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter<Widget>();
  @Output() update = new EventEmitter();

  public predefinedAnswersForm: FormGroup;
  private subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    protected ss: SlugsService,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {
    this.predefinedAnswersForm = this.fb.group({
      messages: this.fb.array([this.createAnswerGroup()]),
    });
  }

  get formArrayAnswersInformation(): FormArray {
    return this.predefinedAnswersForm.get('messages') as FormArray;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.predefinedAnswersForm.valueChanges.subscribe(() =>
        this.savePredefinedAnswers(),
      ),
    );
  }

  ngAfterViewInit(): void {
    if (this.widgetDetails) {
      this.setInitialControls(
        this.widgetDetails.widget as PredefinedAnswersRequestData,
      );
      this.cdr.detectChanges();
    } else if (this.formArrayAnswersInformation.length === 0) {
      this.addAnswerInput();
      this.cdr.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetDetails && changes.widgetDetails.currentValue) {
      this.setInitialControls(
        this.widgetDetails?.widget as PredefinedAnswersRequestData,
      );
    }
  }

  createAnswerGroup(): FormGroup {
    return this.fb.group({
      answer: new FormControl('', Validators.required),
      link: new FormControl(''),
    });
  }

  addAnswerInput(): void {
    this.ss.savingWidget = false;
    this.formArrayAnswersInformation.push(this.createAnswerGroup());
  }

  savePredefinedAnswers(): void {
    const messages = this.combineMessagesWithLinks();
    if (this.widgetDetails?.id) {
      this.update.emit(messages);
    } else {
      this.save.emit(messages);
    }
    this.ss.savingWidget = false;
  }

  setInitialControls(data: PredefinedAnswersRequestData): void {
    if (data?.messages && Array.isArray(data.messages)) {
      this.formArrayAnswersInformation.clear();
      data.messages.forEach((message, index) => {
        this.formArrayAnswersInformation.push(
          this.fb.group({
            answer: [message, Validators.required],
            link: [data.urlsForAnswersByIndex[index] || ''],
          }),
        );
      });
    } else {
      console.warn('Unexpected data structure for setInitialControls:', data);
    }
  }

  combineMessagesWithLinks(): PredefinedAnswersRequestData {
    const messages: string[] = [];
    const urls: string[] = [];
    this.formArrayAnswersInformation.controls.forEach((control) => {
      const answer = control.get('answer')?.value;
      const link = control.get('link')?.value;
      messages.push(answer);
      link ? urls.push(validateLinkUrl(link)) : urls.push('');
    });
    return { messages, urlsForAnswersByIndex: urls };
  }

  closeModalWindow(): void {
    if (this.ss.savingWidget) {
      this.ss.savingWidget = false;
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.savePredefinedAnswers();
    }
  }

  public removeAll(): void {
    this.formArrayAnswersInformation.clear();
    this.addAnswerInput();
    this.remove.emit(this.widgetDetails);
  }

  private clearFormGroup(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key)?.reset();
    });
  }

  public removeAnswerInput(index: number): void {
    if (this.formArrayAnswersInformation.length > 1) {
      this.formArrayAnswersInformation.removeAt(index);
    } else {
      this.clearFormGroup(this.formArrayAnswersInformation.at(0) as FormGroup);
      this.requestRemove();
    }
  }

  public requestRemove(): void {
    if (this.widgetDetails) {
      this.remove.emit(this.widgetDetails);
    }
  }

  public onLinkBlur(index: number): void {
    const control = this.formArrayAnswersInformation.at(index).get('link');
    if (control) {
      const validatedLink = validateLinkUrl(control.value);
      control.setValue(validatedLink, { emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
