import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Slug } from './../../../../@core/domain/Slug';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';
import { MixpanelService } from './../../../../@core/services/mixpanel.service';
import { UploadWidgetService } from '../../../../@core/services/automations/upload-widget.service';
import { AuthService } from '../../../../@core/services/auth.service';
import { NotificationsService } from '../../../../@core/services/notifications.service';

@Component({
  selector: 'norby-slug-content-header',
  templateUrl: './slug-content-header.component.html',
  styleUrls: ['./slug-content-header.component.css'],
})
export class SlugContentHeaderComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthService,
    private mx: MixpanelService,
    protected ss: SlugsService,
    private uws: UploadWidgetService,
    public ns: NotificationsService,
  ) {}

  removeMark = false;
  isOpenModal = false;
  merchantApiKey: string | null = null;

  private widgetInitialized: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.authService.isAuthenticated
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.merchantApiKey = localStorage.getItem('ma');
          if (!this.widgetInitialized) {
            this.uws.initializeChatWidget(this.merchantApiKey);
            this.widgetInitialized = true;
          }
        }
      });
  }

  public toggleSlug(active: boolean, slug: Slug): void {
    this.mx.track('click_activation_switcher', 'automations', {
      key: 'action',
      value: active ? 'activating' : 'disabling',
    });
    if (slug?.approved) {
      this.ss.editSlugState(slug, active);
    }
  }

  public removeSlug(id: Slug['id']): void {
    this.mx.track('click_remove_automation', 'automations');
    this.ss.deleteSlug(id);
    this.isOpenModal = false;
  }

  saveSlug(slug: Slug): void {
    this.ns.setAnswerWarning(slug);
    this.ns.setContentWarning(slug);
    if (!this.ns.contentWarning && !this.ns.answerWarning) {
      this.uws.initializeChatWidget(this.merchantApiKey);
      if (slug.type === 'manual') {
        this.ss.updateFullManualSlug(slug);
      } else if (slug.type === 'automatic') {
        slug?.id === 0
          ? this.ss.createFullAutoSlug(slug)
          : this.ss.updateFullAutoSlug(slug);
      }
    }
  }

  showAutomationList(): void {
    this.ss.selectedSlug$.next(undefined);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
