import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Component({
  selector: 'norby-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.css'],
})
export class SearchSelectComponent implements OnChanges {
  public searchSelectState = false;
  @Input() currentValue: string | null = null;
  @Input() placeHolder: string | null = null;
  @Input() options: string[] | null = null;
  @Input() chatOptions: string[] | null = null;
  @Input() userOptions: string[] | null = null;
  @Input() isOpenToTop: boolean = false;
  @Input() hasHeader = false;
  @Output() selectedOption = new EventEmitter();

  public optionGroups: Array<{ label: string; options: string[] | null }> = [];

  constructor(private ref: ElementRef) {}

  ngOnChanges(): void {
    this.setDefaultValue();
    this.optionGroups = this.getOptionGroups();
  }

  setDefaultValue(): void {
    const firstAvailableOption =
      this.options?.[0] ||
      this.chatOptions?.[0] ||
      this.userOptions?.[0] ||
      null;
    if (!this.currentValue && !this.placeHolder && firstAvailableOption) {
      this.currentValue = firstAvailableOption;
    }
  }

  getOptionGroups(): Array<{ label: string; options: string[] | null }> {
    return [
      { label: 'Chat Attributes', options: this.chatOptions },
      { label: 'User Attributes', options: this.userOptions },
    ].filter((group) => group.options && group.options.length);
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.searchSelectState = false;
    }
  }

  toggle(): void {
    this.searchSelectState = !this.searchSelectState;
  }

  selectOption(option: string): void {
    this.currentValue = option;
    this.selectedOption.emit(option);
    this.searchSelectState = false;
  }
}
