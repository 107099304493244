import { BehaviorSubject } from 'rxjs';
import { Answer, Answers } from '../types/Automations/Answer';
import { Question, QuestionsDTO } from '../types/Automations/Question';
import { Content, SlugDTO } from '../types/Automations/SlugModel';
import {
  MultiLangWidgets,
  MultiLangWidgetsDTO,
} from '../types/Automations/Widgets/MultiLangWidgets';
import { WidgetDTO } from '../types/Automations/Widgets/Widget';
import { Trigger } from '../types/Automations/TriggerTypes';
import { getLocaleById, Locale } from './Locale';
import { initializeLangObject } from './utils';

export class Slug {
  botId: number;
  id: number;
  slug: string;
  approved: boolean;
  answerFirst: BehaviorSubject<string>;
  active: boolean;
  questions: BehaviorSubject<QuestionsDTO>;
  questionsCount: BehaviorSubject<number>;
  isSelected$: BehaviorSubject<boolean>;
  answers: Answers | null;
  isNew: boolean;
  widgets$: BehaviorSubject<MultiLangWidgets[]>;
  hasUnsavedChanges: boolean;
  type: string;
  content: Content;
  trigger$: BehaviorSubject<Trigger | null>;

  private static baseSlug(
    type: 'manual' | 'automatic',
    trigger: Trigger | null = null,
  ): Slug {
    return new Slug({
      id: 0,
      bot_id: 0,
      slug: '',
      approved: 0,
      answer_first: '',
      active: 0,
      questions: initializeLangObject<Question>(),
      questions_count: 0,
      isSelected: false,
      answers: initializeLangObject<Answer>(),
      widgets: undefined,
      isNew: true,
      hasUnsavedChanges: false,
      type,
      content: { content: '', created_at: '', updated_at: '' },
      trigger,
    });
  }

  public static emptyManualSlug(trigger?: Trigger): Slug {
    return this.baseSlug('manual', trigger || null);
  }

  public static emptyAutomaticSlug(): Slug {
    return this.baseSlug('automatic');
  }

  public static emptySlugDTO(): SlugDTO {
    const answers = initializeLangObject<Answer>();
    answers['en'] = [
      { id: 1, slug_id: 1, language_id: 1, text: 'answer text' },
    ];

    return {
      id: 1,
      bot_id: 1,
      slug: '',
      approved: 0,
      answer_first: 'answer text',
      active: 0,
      questions: initializeLangObject<Question>(),
      questions_count: 1,
      isSelected: false,
      answers,
      widgets: undefined,
      isNew: true,
      hasUnsavedChanges: false,
      type: 'automatic',
      content: { content: '', created_at: '', updated_at: '' },
      trigger: 'greeting',
    };
  }

  constructor(slugDTO: SlugDTO) {
    this.botId = slugDTO.bot_id;
    this.id = slugDTO.id;
    this.slug = slugDTO.slug;
    this.approved = !!slugDTO.approved;
    this.answerFirst = new BehaviorSubject<string>(slugDTO.answer_first);
    this.active = !!slugDTO.active;
    this.questions = new BehaviorSubject<QuestionsDTO>(slugDTO.questions ?? {});
    this.questionsCount = new BehaviorSubject<number>(slugDTO.questions_count);
    this.isSelected$ = new BehaviorSubject<boolean>(slugDTO.isSelected);
    this.answers = slugDTO.answers;
    this.isNew = !this.id;
    this.widgets$ = new BehaviorSubject<MultiLangWidgets[] | []>(
      (slugDTO.widgets ?? [])
        .map((widget) => this.widgetJSONConverter(widget))
        .filter((widget): widget is MultiLangWidgets => widget !== null),
    );
    this.hasUnsavedChanges = false;
    this.type = slugDTO.type;
    this.content = slugDTO.content;
    this.trigger$ = new BehaviorSubject(slugDTO.trigger);
  }

  setUnsavedChanges(state: boolean): void {
    this.hasUnsavedChanges = state;
  }

  updateActiveState(state: boolean): void {
    this.active = state;
  }

  setSelected(selected: boolean): void {
    this.isSelected$.next(selected);
  }

  updateAnswersState(answers: Answers | null, languageId: number): void {
    const lang = getLocaleById(languageId);
    const text = answers?.[lang]?.[0]?.text;
    if (text) {
      this.answerFirst.next(text.replace(/<\/?[^>]+(>|$)/g, ''));
    }
    this.answers = answers;
  }

  setName(name: string): void {
    if (this.slug !== name) {
      this.setUnsavedChanges(true);
      this.slug = name;
    }
  }

  setContent(content: string): void {
    this.content = { ...this.content, content };
  }

  addQuestion(question: Partial<Question>, lang: Locale): void {
    this.setUnsavedChanges(true);
    const questionsCount = this.questionsCount.value + 1;
    this.questionsCount.next(questionsCount);
    const langArray = this.questions.value[lang];
    langArray.push(question as Question);
  }

  removeQuestion(lang: Locale, questionId: number): void {
    this.setUnsavedChanges(true);
    const questionsCount = this.questionsCount.value - 1;
    this.questionsCount.next(questionsCount);
    this.questions.value[lang].splice(
      this.questions.value[lang].findIndex((el) => el.id === questionId),
      1,
    );
  }

  removeQuestionForm(formId: number, lang: Locale): void {
    this.setUnsavedChanges(true);
    const questionsCount = this.questionsCount.value - 1;
    this.questionsCount.next(questionsCount);
    this.questions.value[lang].splice(
      this.questions.value[lang].findIndex((el) => el.formId === formId),
      1,
    );
  }

  editQuestion(status: number, questionId: number, lang: Locale): void {
    this.setUnsavedChanges(true);
    const question = this.findQuestion(questionId);
    const newState = { ...question, active: status };
    this.questions.value[lang].splice(
      this.questions.value[lang].findIndex((el) => el.id === questionId),
      1,
      newState,
    );
  }

  findQuestion(questionId: number): Question {
    const questionValues = Object.values(this.questions.value);
    let question!: Question;
    for (const questions of questionValues) {
      const desired = questions.find(
        (el: { id: number }) => el.id === questionId,
      );
      if (desired) {
        question = desired;
      }
    }
    return question;
  }

  getAllAnswersCount(): number {
    let totalAnswers = 0;
    if (this.answers) {
      for (const [, value] of Object.entries(this.answers)) {
        if (value) {
          totalAnswers = totalAnswers + value.length;
        }
      }
    }
    return totalAnswers;
  }

  addShortLangForWidget(widgets: WidgetDTO[] | WidgetDTO): MultiLangWidgetsDTO {
    const resultWidget = {} as MultiLangWidgetsDTO;
    if (Array.isArray(widgets)) {
      for (const w of widgets) {
        const shortLang = getLocaleById(w.language_id);
        resultWidget[shortLang] = { ...w };
      }
      return resultWidget;
    }
    const sl = getLocaleById(widgets.language_id);
    resultWidget[sl] = { ...widgets };
    return resultWidget;
  }

  widgetJSONConverter(data?: MultiLangWidgetsDTO): MultiLangWidgets | null {
    if (!data || typeof data !== 'object') {
      return null;
    }
    const resultWidget = {} as MultiLangWidgets;
    for (const key of Object.keys(data)) {
      const widgetDto = data[key as Locale];
      if (Array.isArray(widgetDto)) {
        return null;
      }
      resultWidget[key as Locale] = {
        ...widgetDto,
        widget:
          typeof widgetDto.widget === 'string'
            ? JSON.parse(widgetDto.widget)
            : widgetDto.widget,
      };
    }
    return resultWidget;
  }
}
