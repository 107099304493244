<ng-container>
  <div>
    <norby-search-select
      [hasHeader]="true"
      [isOpenToTop]="true"
      [chatOptions]="chatAttributeNames$ | async"
      [userOptions]="userAttributeNames$ | async"
      [currentValue]="currentValue"
      [placeHolder]="'Select an attribute ...'"
      (selectedOption)="selectAttribute($event)"
    />
  </div>
</ng-container>
