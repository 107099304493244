import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { Attribute } from '../types/Attribute';
import { FilterConditions } from '../types/FilterConditions';
import { AttributesApiService } from './api/attributes-api.service';

@Injectable({
  providedIn: 'root',
})
export class ChatSearchStateService {
  constructor(private aas: AttributesApiService) {
    this.loadAttributes();
    this.aas.getFilterConditionsList().subscribe((result) => {
      this.allFilterConditions$.next(result);
    });
  }

  chatAttributes$ = new BehaviorSubject<Attribute[]>([]);
  userAttributes$ = new BehaviorSubject<Attribute[]>([]);
  selectedChatAttribute$ = new BehaviorSubject<Attribute | null>(null);
  allFilterConditions$ = new BehaviorSubject<FilterConditions | null>(null);
  selectedChatFilterConditions$ = new BehaviorSubject<string[]>([]);
  selectedChatCondition$ = new BehaviorSubject<string | null>(null);
  selectedChatConditionValue$ = new BehaviorSubject<string | null>(null);

  loadAttributes(): void {
    this.aas.getDefaultChatAttributes().subscribe((result) => {
      if (result.length) {
        this.chatAttributes$.next(result);
      }
    });
    this.aas.getDefaultUserAttributes().subscribe((result) => {
      if (result.length) {
        this.userAttributes$.next(result);
      }
    });
  }

  setSelectedChatAttributeByName(name: string): void {
    const attributes = this.chatAttributes$.getValue();
    const selected = attributes.find((a) => a.name === name);
    if (selected) {
      this.selectedChatAttribute$.next(selected);
    }
  }

  setSelectedChatCondition(name: string | null): void {
    this.selectedChatCondition$.next(name);
  }

  setSelectedChatConditionValue(value: string | null): void {
    this.selectedChatConditionValue$.next(value);
  }

  resetChatSearch(): void {
    this.selectedChatAttribute$.next(null);
    this.selectedChatCondition$.next(null);
    this.selectedChatConditionValue$.next(null);
  }

  get currentFilterConditions(): Observable<string[]> {
    return zip(this.allFilterConditions$, this.selectedChatAttribute$).pipe(
      map((merged) => {
        const [conditions, selectedAttribute] = merged;
        if (conditions && selectedAttribute) {
          const conditionsObject = conditions[selectedAttribute?.data_type];
          if (conditionsObject) {
            return Object.values(conditionsObject);
          }
        }
        return [];
      }),
    );
  }
}
