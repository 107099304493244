import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'norby-additional-items-button',
  templateUrl: './additional-items-button.component.html',
  styleUrl: './additional-items-button.component.css',
})
export class AdditionalItemsButtonComponent {
  @Input() placeholder: string = '';
  @Output() addItem = new EventEmitter<string>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      optionValue: [''],
    });
  }

  emitValue(): void {
    const optionValue = this.form.get('optionValue')?.value;
    if (optionValue) {
      this.addItem.emit(optionValue);
      this.form.reset();
    }
  }
}
