import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, shareReplay } from 'rxjs';
import { Slug } from '../../domain/Slug';
import { Widget, WidgetDTO } from '../../types/Automations/Widgets/Widget';
import { WidgetTypes } from '../../types/Automations/Widgets/WidgetTypes';
import { SlugsApiService } from '../api/slugs-api.service';
import { Locale } from '../../domain/Locale';
import { SelectOption } from '../../../components/select/select.component';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  constructor(private sas: SlugsApiService) {}
  private currentWidgetType$ = new BehaviorSubject<WidgetTypes | null>(null);
  private currentWidgets$ = new BehaviorSubject<Widget[]>([]);

  public currentWidgetsObservable$: Observable<Widget[]> =
    this.currentWidgets$.asObservable();

  public widgetOptions$: Observable<SelectOption[] | null> = this.sas
    .getWidgetTypes()
    .pipe(
      map((w) =>
        Object.entries(w).map(([key, value]) => ({
          key,
          value,
        })),
      ),
      shareReplay(1),
    );

  public convertWidgetsToString(): WidgetDTO[] {
    return this.currentWidgets$.getValue().map((widget) => ({
      ...widget,
      widget: JSON.stringify(widget.widget),
    }));
  }

  public getWidgetType(slug: Slug): WidgetTypes | 'none' {
    const widgets$ = slug.widgets$.value;

    if (widgets$.length > 0) {
      const widget = widgets$.find((w) => Object.values(w)[0]?.type);

      if (widget) {
        const widgetType = Object.values(widget)[0]?.type;
        if (widgetType) {
          return widgetType;
        }
      }
    }

    return 'none';
  }

  reset(): void {
    if (this.currentWidgetType$.value) {
      this.currentWidgetType$.next(null);
    }
  }

  public resetCurrentWidgets(): void {
    this.currentWidgets$.next([]);
  }

  // When widgets comes from backend
  public updateWidgetsFromDomain(
    widgetsFromServer: Partial<Record<Locale, Widget>>[],
    locale: Locale,
  ): void {
    const parsedWidgets: Widget[] = widgetsFromServer
      .map((item) => {
        const widgetData = item[locale];

        if (!widgetData) {
          return null;
        }

        const parsedWidgetData = {
          ...widgetData,
          widget: widgetData.widget,
        };

        return {
          id: parsedWidgetData.id,
          slug_id: parsedWidgetData.slug_id,
          language_id: parsedWidgetData.language_id,
          type: parsedWidgetData.type,
          widget: parsedWidgetData.widget,
        };
      })
      .filter((widget) => widget !== null) as Widget[];
    this.currentWidgets$.next(parsedWidgets);
  }

  public setWidgetToObservable(widgetOrWidgets?: Widget | Widget[]): void {
    if (Array.isArray(widgetOrWidgets) && widgetOrWidgets.length === 0) {
      this.resetCurrentWidgets();
      return;
    }

    if (widgetOrWidgets) {
      const updatedWidgets = Array.isArray(widgetOrWidgets)
        ? widgetOrWidgets
        : [widgetOrWidgets];
      this.currentWidgets$.next(updatedWidgets);
    }
  }

  public setCurrentWidgetType(currentWidgetType: WidgetTypes | null): void {
    this.currentWidgetType$.next(currentWidgetType);
  }
}
