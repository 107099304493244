<div class="option-button__wrapper">
  <form class="form" [formGroup]="form">
    <input
      formControlName="optionValue"
      class="button-input input-description"
      [placeholder]="placeholder"
      (keyup.enter)="emitValue()"
    />
    <norby-add-new-button
      [bgColor]="'var(--grayFA)'"
      [hoverColor]="'var(--grayF1)'"
      [plusColor]="'var(--nrb-ob-color)'"
      class="option-button"
      (click)="emitValue()"
    />
  </form>
</div>
