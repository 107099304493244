import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserLanguagesService } from '../../../../../@core/services/user-languages.service';
import { WidgetCommon } from '../../../../../@core/types/Automations/Widgets/Widget';
import { newChatButtonValues } from './constants/newChatButtonValues';
import { Subscription } from 'rxjs';

export type NewChatButtonWidget = WidgetCommon & {
  widget: {
    name: string;
  };
};

@Component({
  selector: 'norby-new-chat-button-widget',
  templateUrl: './new-chat-button-widget.component.html',
  styleUrls: ['./new-chat-button-widget.component.css'],
})
export class NewChatButtonWidgetComponent implements OnInit, OnDestroy {
  private selectedLang = this.uls.currentLanguage$;
  private _widgetDetails: NewChatButtonWidget | undefined;
  private subscription: Subscription = new Subscription();

  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input()
  set widgetDetails(value: NewChatButtonWidget | undefined) {
    this._widgetDetails = value;
    if (value?.widget?.name) {
      this.newButtonForm.setValue({ name: value.widget.name });
    }
  }

  get widgetDetails(): NewChatButtonWidget | undefined {
    return this._widgetDetails;
  }

  newButtonForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private uls: UserLanguagesService,
  ) {
    this.newButtonForm = this.fb.group({
      name: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (!this.widgetDetails) {
      setTimeout(() => {
        if (this.newButtonForm.valid) {
          this.save.emit({ new_chat_button: this.newButtonForm.value });
        }
      }, 0);
    }

    this.subscription.add(
      this.newButtonForm.valueChanges.subscribe(() => {
        this.saveNewChatButton();
      }),
    );

    const defaultText = newChatButtonValues['en'];
    const translatedText =
      newChatButtonValues[this.selectedLang.value] || defaultText;

    this.newButtonForm.setValue({ name: translatedText });
  }

  saveNewChatButton() {
    const value = this.newButtonForm.getRawValue();
    if (this.widgetDetails?.id) {
      this.update.emit({ name: value });
    } else {
      this.save.emit({ name: value });
    }
  }

  removeNewChatButton(): void {
    this.remove.emit(this.widgetDetails);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
