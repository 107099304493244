<li [class.active]="isActive" class="slug-card">
  <div class="arrow-pointer">
    <norby-pointer-arrow
      [color]="isActive ? 'var(--nrb-ob-light-color)' : '#A8A8A8'"
    />
  </div>
  <div class="slug-content-inner" *ngIf="slug">
    <div class="slug-questions-wrapper">
      <h4 class="slug-question">{{ slug.slug }}</h4>
      <span
        *ngIf="
          slug?.type === 'manual' && (slug.trigger$ | async) !== 'questions'
        "
        [class.selected]="isActive"
        class="title-info"
        >{{ slug.trigger$ | async | teamAssignStrConverter }}</span
      >
      <norby-clock-icon
        [color]="isActive ? 'white' : 'black'"
        *ngIf="!slug.approved"
      ></norby-clock-icon>
      <span
        class="questions-count"
        [class.selected]="isActive"
        *ngIf="slug.questionsCount | async"
        >{{ slug.questionsCount | async }}
      </span>
      <span
        *ngIf="slug?.type === 'automatic'"
        [class.selected]="isActive"
        class="title-info"
        >Auto
      </span>
    </div>
    <p
      class="slug-text"
      *ngIf="!(slug.answerFirst | async) && slug.type === 'manual'"
    >
      No answer added yet…
    </p>
    <p class="slug-text" *ngIf="slug.type === 'automatic' && !slug.content">
      No content provided yet…
    </p>
    <p class="slug-text" *ngIf="slug.type === 'automatic' && slug.content">
      {{ convertMessage(slug.content.content) | removeSpaces }}
    </p>
    <p class="slug-text" *ngIf="slug.answerFirst | async">
      {{ slug.answerFirst | async | removeSpaces }}
    </p>
  </div>
</li>
