<section class="container" [ngStyle]="{ position: getPositionStyle() }">
  <norby-new-slug />
  <div *ngIf="ss.botOptions.length">
    <norby-select
      (setChangeValue)="loadBotData($event)"
      [currentValue]="ss.botOptions[0].value || ''"
      [optionValues]="ss.botOptions"
      [textColor]="'black'"
      [background]="'var(--grayFA)'"
    ></norby-select>
    <norby-slugs-tabs />
    <norby-slug-search />
  </div>
  <norby-spinner *ngIf="showSpinner | async" />
  <ng-container *ngIf="ss.currentTab$ | async as currentTab">
    <!--  Active slugs-->
    <ul
      (scrolled)="ss.getPaginatedSlugs('Active')"
      *ngIf="showActiveSlugsTab | async"
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      class="slug-list"
      infinite-scroll
    >
      <ng-container *ngFor="let slug of ss.enabledSlugs$ | async">
        <norby-slug-card
          (click)="getSlugData(slug)"
          [slug]="slug"
          [isActive]="ss.selectedSlug$.value?.id === slug.id"
        ></norby-slug-card>
      </ng-container>
      <norby-slugs-notification
        *ngIf="currentTab === 'Active' && (ps.hasCompletePayment | async)"
      />
      <norby-spinner *ngIf="ss.slugsLoader$ | async" />
      <div
        *ngIf="
          ss.enabledSlugs$.value.length === 0 &&
          !(ss.spinnerSidebar$ | async) &&
          !(ps.hasCompletePayment | async)
        "
        class="empty-list-stub"
      >
        <h4 class="empty-list-title">No automations to show</h4>
      </div>
    </ul>

    <!--  Disabled slugs-->
    <ul
      (scrolled)="ss.getPaginatedSlugs('Disabled')"
      *ngIf="currentTab === 'Disabled'"
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      class="slug-list"
      infinite-scroll
    >
      <ng-container *ngFor="let slug of ss.disabledSlugs$ | async">
        <norby-slug-card
          (click)="getSlugData(slug)"
          [slug]="slug"
          [isActive]="ss.selectedSlug$.value?.id === slug.id"
        ></norby-slug-card>
      </ng-container>
      <norby-spinner *ngIf="ss.slugsLoader$ | async" />
      <div
        *ngIf="(ss.disabledSlugs$ | async)?.length === 0"
        class="empty-list-stub"
      >
        <h4 class="empty-list-title">No automations to show</h4>
      </div>
    </ul>
  </ng-container>
</section>

<norby-modal
  *ngIf="
    !ns.contentWarning && !ns.answerWarning && (ss.hasUnsavedChanges$ | async)
  "
  (closeModal)="closeLeavingWithoutSaveModal()"
  title="Leave Without Saving?"
>
  <div slot="content">
    <p class="modal-content">
      You have unsaved changes. Are you sure you want to leave without saving?
    </p>
    <div class="remove-actions">
      <button (click)="leaveWithoutSave()" class="secondary-button">
        Leave
      </button>
      <button (click)="leaveWithSave()" class="main-button">
        Save Changes
      </button>
    </div>
  </div>
</norby-modal>

<norby-modal
  *ngIf="ns.answerWarning"
  (closeModal)="ns.closeAnswerWarningModal()"
  title="Fill the Answer field"
>
  <div slot="content">
    <p class="modal-content">
      The Automation should contain at least one Answer.
    </p>
    <div class="remove-actions">
      <button (click)="ns.closeAnswerWarningModal()" class="main-button">
        Got it!
      </button>
    </div>
  </div>
</norby-modal>
<norby-modal
  *ngIf="ns.contentWarning"
  (closeModal)="ns.closeContentWarningModal()"
  title="Fill the Content field"
>
  <div slot="content">
    <p class="modal-content">The Automation should contain the Content.</p>
    <div class="remove-actions">
      <button (click)="ns.closeContentWarningModal()" class="main-button">
        Got it!
      </button>
    </div>
  </div>
</norby-modal>

<norby-modal
  (closeModal)="closePopup()"
  *ngIf="ss.subscriptionError$ | async as subscriptionError"
  title="Oops…"
>
  <div slot="content">
    <p class="modal-content">{{ subscriptionError }}</p>
    <button (click)="closePopup()" class="main-button-full-width">
      Goi it!
    </button>
  </div>
</norby-modal>

<div *ngIf="isLoadingBots || ss.saving" class="loading_modal">
  <norby-spinner [color]="'#FFFFFF'"></norby-spinner>
</div>
