import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { validateLinkUrl } from '../../../../../@core/validators/linkValidator';
import {
  Widget,
  WidgetCommon,
} from '../../../../../@core/types/Automations/Widgets/Widget';
import { ButtonDetails } from '../../../../../@core/types/Automations/Widgets/ButtonDetails';

export type ButtonWidget = WidgetCommon & {
  widget: { name: string; link: string };
};

@Component({
  selector: 'norby-button-widget',
  templateUrl: './button-widget.component.html',
  styleUrls: ['./button-widget.component.css'],
})
export class ButtonWidgetComponent implements OnInit, OnChanges, AfterViewInit {
  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  _widgetDetails: ButtonWidget | undefined;
  @Input()
  set widgetDetails(value: ButtonWidget | undefined) {
    this._widgetDetails = value;
    if (value?.widget) {
      this.widgetButtonForm.setValue(value.widget);
    }
  }

  get widgetDetails(): ButtonWidget | undefined {
    return this._widgetDetails;
  }

  @ViewChild('name') name: ElementRef | undefined;

  widgetButtonForm: UntypedFormGroup;
  private previousButtonValue: ButtonDetails | null = null;

  constructor(
    private fb: UntypedFormBuilder,
    protected ss: SlugsService,
  ) {
    this.widgetButtonForm = this.fb.group({
      name: ['', Validators.required],
      link: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetDetails?.currentValue) {
      const widget = changes.widgetDetails.currentValue as Widget;
      if (
        widget.widget &&
        (widget.widget as ButtonDetails).name !== undefined &&
        (widget.widget as ButtonDetails).link !== undefined
      ) {
        this.widgetButtonForm.setValue({
          name: (widget.widget as ButtonDetails).name,
          link: (widget.widget as ButtonDetails).link,
        });
      }
    }
  }

  ngOnInit() {
    this.previousButtonValue = this.widgetButtonForm.getRawValue();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (
        this.name &&
        document.activeElement !== this.name.nativeElement &&
        this.name.nativeElement.value === ''
      ) {
        this.name.nativeElement.focus();
      }
    }, 0);
  }

  onBlur() {
    if (this.name) {
      this.name.nativeElement.blur();
    }
  }

  changeButtonWidget(): void {
    if (this.widgetButtonForm.valid) {
      const { name, link } = this.widgetButtonForm.getRawValue();
      const buttonWidget = { name, link: validateLinkUrl(link) };
      this.ss.savingWidget = this.previousButtonValue !== buttonWidget;

      if (this.widgetDetails?.id) {
        this.update.emit(buttonWidget);
      } else {
        this.save.emit(buttonWidget);
      }
    }
  }

  closeModalWindow(): void {
    if (this.ss.savingWidget) {
      this.ss.savingWidget = false;
    }
  }

  removeButton(): void {
    this.remove.emit(this.widgetDetails);
  }
}
