import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { WidgetCommon } from '../../../../../@core/types/Automations/Widgets/Widget';
import { Subscription } from 'rxjs';
import { modules } from '../../../../../pages/automations-page/slug-window/modules';
import { QuillEditorComponent } from 'ngx-quill';
import { setFocusAfterContent } from '../../../../../@core/domain/utils';
import { WidgetService } from '../../../../../@core/services/automations/widget.service';

export type MessageWidget = WidgetCommon & {
  widget: { message: string };
};

@Component({
  selector: 'norby-message-widget',
  templateUrl: './message-widget.component.html',
  styleUrls: ['./message-widget.component.css'],
})
export class MessageWidgetComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  private _widgetDetails: MessageWidget | undefined;
  private previousMessageValue: string | null = null;

  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input()
  set widgetDetails(value: MessageWidget | undefined) {
    this._widgetDetails = value;
    if (value?.widget?.message) {
      this.message.setValue(value.widget.message);
    }
  }

  get widgetDetails(): MessageWidget | undefined {
    return this._widgetDetails;
  }

  @ViewChild('quill') quill: QuillEditorComponent | undefined;

  private readonly subscription: Subscription = new Subscription();
  protected readonly quillModules = modules;
  public message = new FormControl<string>('', {
    validators: [Validators.required],
    nonNullable: true,
  });

  constructor(
    protected ss: SlugsService,
    private ws: WidgetService,
  ) {}

  ngOnInit() {
    this.previousMessageValue = this.message.getRawValue();

    this.subscription.add(
      this.message.valueChanges.subscribe(() => {
        this.changeMessageWidget();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetDetails?.currentValue) {
      const widget = changes.widgetDetails.currentValue as MessageWidget;
      if (widget?.widget?.message !== undefined) {
        this.message.patchValue(widget.widget.message);
      }
    }
  }

  ngAfterViewInit() {
    if (this.quill && this.message && this.message.value === '') {
      this.setFocusOnQuill();
    }
  }

  setFocusOnQuill(): void {
    if (this.quill) {
      this.subscription.add(
        this.quill.onEditorCreated.subscribe(() => {
          setFocusAfterContent(this.quill);
        }),
      );
    }
  }

  changeMessageWidget(): void {
    if (this.message.valid) {
      const rawValue = this.message.getRawValue();
      this.ss.savingWidget = this.previousMessageValue !== rawValue;

      if (this.widgetDetails?.id) {
        this.update.emit({ message: rawValue });
      } else {
        this.save.emit({ message: rawValue });
      }
    }
  }

  closeModalWindow(): void {
    if (this.ss.savingWidget) {
      this.ss.savingWidget = false;
    }
  }

  removeContent(): void {
    this.message.reset();
    setFocusAfterContent(this.quill);
  }

  removeButton(): void {
    this.remove.emit(this.widgetDetails);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
