<div [formGroup]="predefinedAnswersForm" class="predefined-box">
  <ng-container formArrayName="messages">
    <div
      *ngFor="
        let message of formArrayAnswersInformation.controls;
        let i = index
      "
      [formGroupName]="i"
    >
      <div class="input-inner">
        <div class="required-flex">
          <input
            formControlName="answer"
            class="button-input"
            type="text"
            placeholder="Enter a predefined answer …"
          />
          <small class="required-asterisk">*</small>
        </div>
        <div class="widget-input">
          <input
            formControlName="link"
            class="button-input"
            type="text"
            placeholder="Paste a link …"
            (blur)="onLinkBlur(i)"
          />
          <norby-bin
            (remove)="removeAnswerInput(i)"
            [showBin]="true"
            [item]="'Answer'"
          ></norby-bin>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="widget-input">
    <button
      (click)="addAnswerInput()"
      [style]="{ width: '100%' }"
      class="secondary-button"
      type="button"
      [disabled]="!predefinedAnswersForm.valid"
    >
      Add
    </button>
    <norby-remove-all-bin
      *ngIf="formArrayAnswersInformation.length > 1"
      (remove)="removeAll()"
      [showBin]="true"
      [items]="'Answers'"
    ></norby-remove-all-bin>
  </div>
</div>
<norby-modal
  *ngIf="ss.savingWidget && !predefinedAnswersForm.valid"
  title="Fill all required fields."
  (closeModal)="closeModalWindow()"
>
  <div slot="content">
    <div class="modal-content">
      <p>Field "Enter a predefined answer …" is required.</p>
    </div>
    <button class="main-button" (click)="closeModalWindow()">Got it!</button>
  </div>
</norby-modal>
