import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.css'],
})
export class RadioButtonComponent {
  @Input() checked: boolean | undefined = false;
  @Input() color = 'var(--grayF1)';
  @Input() cursorPointer = true;
  @Output() radioButtonClick = new EventEmitter();

  public onRadioClick(): void {
    this.checked = !this.checked;
    this.radioButtonClick.emit(this.checked);
  }
}
