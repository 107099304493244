<ng-container>
  <norby-form-widget-container
    [isVisible]="true"
    [isMandatory]="isMandatory"
    (toggleMandatory)="toggleForm()"
  >
    <div slot="content" class="input-inner">
      <div class="input-form radio-form__container">
        <div class="input-inner radio-form__inner">
          <div class="radio-form__content">
            <div class="required-flex" [ngStyle]="{ 'margin-bottom': '20px' }">
              <input
                [formControl]="radioWidgetFormLabel"
                [ngClass]="{
                  'button-input': true,
                  'input-description': true,
                  'input-description--error':
                    radioWidgetFormLabel.invalid &&
                    radioWidgetFormLabel.touched,
                }"
                type="text"
                placeholder="Enter a radio buttons title …"
              />
              <small
                *ngIf="isMandatory"
                [ngStyle]="{ position: 'absolute', top: '0', right: '-10px' }"
                class="required-asterisk"
              >
                *
              </small>
              <div
                *ngIf="
                  radioWidgetFormLabel.invalid && radioWidgetFormLabel.touched
                "
                class="error-message"
              >
                Radio buttons label is required.
              </div>
            </div>
            <div class="widget-input">
              <norby-additional-items-button
                [placeholder]="'Add radio button'"
                (addItem)="addRadio($event)"
              />
            </div>
          </div>
          <div class="radio__container">
            <ng-container *ngFor="let radioLabel of radioButtons; index as i">
              <div class="radio__wrapper">
                <norby-radio-button
                  [checked]="!!radioLabel.isChecked"
                  (radioButtonClick)="onRadioClick($event, i)"
                />
                <p class="radio__label">
                  {{
                    radioLabel.value.charAt(0).toUpperCase() +
                      radioLabel.value.slice(1)
                  }}
                </p>
                <norby-bin [showBin]="true" (remove)="removeItem(i)" />
              </div>
            </ng-container>
          </div>
        </div>
        <norby-bin
          (remove)="removeRadioFormInput()"
          [showBin]="true"
          [item]="'Widget'"
        ></norby-bin>
      </div>
      <norby-full-attributes-select
        [currentValue]="attributeValue"
        (selectChange)="onSelectChange($event)"
      />
    </div>
  </norby-form-widget-container>
</ng-container>
